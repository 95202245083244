import React, { useState } from 'react';
import { postData } from '../../apiService'; // Виправлений шлях
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const data = await postData('/api/register', { username, email, password });
            setSuccess(data.message);
            setTimeout(() => {
                navigate('/WorkPage'); // Перенаправлення на WorkPage через 1 секунду
            }, 1000);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Реєстрація</h2>
            {error && <p style={styles.errorMessage}>Помилка: {error}</p>}
            {success && <p style={styles.successMessage}>{success}</p>}
            <form onSubmit={handleRegister} style={styles.form}>
                <div style={styles.formGroup}>
                    <label>Ім'я:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label>Електронна пошта:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label>Пароль:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <button type="submit" style={styles.button}>Зареєструватися</button>
            </form>
        </div>
    );
};

const styles = {
    container: {
        width: '300px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
    },
    title: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    input: {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    button: {
        width: '100%',
        padding: '10px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#4CAF50',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center',
        marginBottom: '15px',
    },
    successMessage: {
        color: 'green',
        textAlign: 'center',
        marginBottom: '15px',
    }
};

export default RegisterPage;
