// apiService.js

// Базовий URL з .env
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Універсальна функція fetch з тайм-аутом
const fetchWithTimeout = async (url, options = {}, timeout = 5000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(id);
    return response;
};

// Універсальна функція fetch для всіх типів запитів
const apiFetch = async (endpoint, method = 'GET', data = null) => {
    try {
        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        const response = await fetchWithTimeout(`${API_URL}${endpoint}`, options, 10000); // Тайм-аут 10 секунд

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error(`Error in ${method} request to ${endpoint}:`, error.message);
        throw new Error(`${method} request to ${endpoint} failed: ${error.message}`);
    }
};

// Функція для отримання даних (GET-запит)
export const getData = async (endpoint) => {
    return apiFetch(endpoint, 'GET');
};

// Функція для відправки даних (POST-запит)
export const postData = async (endpoint, data) => {
    return apiFetch(endpoint, 'POST', data);
};

// Функція для оновлення даних (PUT-запит)
export const putData = async (endpoint, data) => {
    return apiFetch(endpoint, 'PUT', data);
};

// Функція для видалення даних (DELETE-запит)
export const deleteData = async (endpoint) => {
    return apiFetch(endpoint, 'DELETE');
};
