import React from 'react';

const SubmitIdeaPage = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Подати Ідею</h2>
      <p>Тут ви можете подати свою ідею. Будь ласка, заповніть форму нижче.</p>
      <form>
        <div style={{ marginBottom: '10px' }}>
          <label>Назва ідеї</label>
          <input type="text" style={{ width: '100%', padding: '8px' }} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>Опис ідеї</label>
          <textarea style={{ width: '100%', padding: '8px', minHeight: '100px' }} />
        </div>
        <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}>Надіслати</button>
      </form>
    </div>
  );
};

export default SubmitIdeaPage;
