import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';

const WorkPage = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setIsMessagesOpen(false);
  };

  const toggleMessages = () => {
    setIsMessagesOpen(!isMessagesOpen);
    setIsNotificationsOpen(false);
  };

  const brandColorPrimary = '#003366';
  const brandColorSecondary = '#0066cc';

  return (
    <div style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif', backgroundColor: '#f0f2f5' }}>
      {isSidebarOpen && (
        <aside style={{
          width: '250px',
          backgroundColor: brandColorPrimary,
          color: 'white',
          padding: '20px',
          position: 'fixed',
          height: '100%',
          overflowY: 'auto',
          zIndex: 1000,
          transition: 'transform 0.3s ease-in-out',
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
        }}>
          <h2 style={{ color: 'white', marginBottom: '30px' }}>Avtologistika</h2>
          <nav>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ marginBottom: '15px' }}>
                <Link to="/submit-idea" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Подати ідею</Link>
              </li>
              <li style={{ marginBottom: '15px' }}>
                <Link to="/submit-problem" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Подати проблему</Link>
              </li>
              <li style={{ marginBottom: '15px' }}>
                <Link to="/projects" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Мої участі у проєктах</Link>
              </li>
              <li style={{ marginBottom: '15px' }}>
                <Link to="/subscriptions" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Мої підписки</Link>
              </li>
            </ul>
          </nav>
        </aside>
      )}

      <div style={{ flex: 1, marginLeft: isSidebarOpen ? '250px' : '0', transition: 'margin-left 0.3s' }}>
        <header style={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          padding: '10px 20px', 
          backgroundColor: '#f8f9fa', 
          borderBottom: '1px solid #ddd' 
        }}>
          <button onClick={toggleSidebar} style={{
            background: 'none', 
            border: 'none', 
            cursor: 'pointer', 
            fontSize: '1.5em', 
            color: brandColorPrimary
          }}>☰</button>
          <h1 style={{ color: brandColorPrimary, fontSize: '1.5em' }}>Робоча сторінка</h1>
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <div style={{ position: 'relative' }}>
              <button onClick={toggleNotifications} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                🔔
              </button>
            </div>
            <div style={{ position: 'relative' }}>
              <button onClick={toggleMessages} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                📧
              </button>
            </div>
          </div>
        </header>

        <main style={{ padding: '20px' }}>
          <section style={{ display: 'flex', gap: '20px', marginBottom: '30px' }}>
            <div style={{
              flex: 1,
              padding: '20px',
              backgroundColor: brandColorSecondary,
              color: 'white',
              borderRadius: '10px',
              textAlign: 'center'
            }}>
              <h3>Подані ідеї</h3>
              <p>15</p>
            </div>
            <div style={{
              flex: 1,
              padding: '20px',
              backgroundColor: '#28a745',
              color: 'white',
              borderRadius: '10px',
              textAlign: 'center'
            }}>
              <h3>Подані проблеми</h3>
              <p>8</p>
            </div>
            <div style={{
              flex: 1,
              padding: '20px',
              backgroundColor: '#ffc107',
              color: 'white',
              borderRadius: '10px',
              textAlign: 'center'
            }}>
              <h3>Мої участі у проєктах</h3>
              <p>3</p>
            </div>
          </section>

          <section style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
            <h2>Історія активностей</h2>
            <p>Тут можна відобразити історію активностей або додатковий контент, що вам потрібен.</p>
          </section>
        </main>

        <footer style={{ padding: '10px', backgroundColor: '#f8f9fa', textAlign: 'center' }}>
          <p>© 2024 Avtologistika. Всі права захищені.</p>
        </footer>
      </div>
    </div>
  );
};

export default WorkPage;
