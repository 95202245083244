// src/components/Footer/Footer.js
import React from 'react';

const Footer = () => (
  <footer style={{ padding: '10px', backgroundColor: '#003366', color: 'white', textAlign: 'center' }}>
    <p>&copy; 2024 Avtologistika. Всі права захищені.</p>
  </footer>
);

export default Footer;
