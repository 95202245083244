import React, { useEffect, useState } from 'react';
import { getData } from '../../apiService';

const Header = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Виклик API для отримання даних про користувача
    getData('/api/user')
      .then((data) => setUser(data))
      .catch((error) => console.error("Помилка при отриманні даних користувача:", error));
  }, []);

  return (
    <header style={{ padding: '10px', backgroundColor: '#003366', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h1>Avtologistika</h1>
      <nav>
        {user ? (
          <>
            <span style={{ marginRight: '15px' }}>Привіт, {user.name}</span>
            <a href="/logout" style={{ color: 'white', marginRight: '15px' }}>Вихід</a>
          </>
        ) : (
          <>
            <a href="/login" style={{ color: 'white', marginRight: '15px' }}>Вхід</a>
            <a href="/register" style={{ color: 'white' }}>Реєстрація</a>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
