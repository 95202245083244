import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Запит на авторизацію через API
      const response = await fetch('https://lean.avtologistika.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // При успішному вході збережіть токен (якщо є) та перенаправте на WorkPage
        localStorage.setItem('token', data.token); // Збереження токена у локальному сховищі
        navigate('/WorkPage'); // Перенаправлення на WorkPage
      } else {
        // Відображення помилки, якщо авторизація неуспішна
        setError(data.message || 'Невдала авторизація');
      }
    } catch (error) {
      console.error('Помилка:', error);
      setError('Сталася помилка під час авторизації');
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '40px', maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
      <h2 style={{ color: '#333', fontWeight: 'bold' }}>Вхід</h2>
      <form onSubmit={handleLogin}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontSize: '1em', color: '#555' }}>Електронна пошта</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '1em' }}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', fontSize: '1em', color: '#555' }}>Пароль</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '1em' }}
            required
          />
        </div>
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '1em',
            color: 'white',
            backgroundColor: '#007BFF',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Увійти
        </button>
        {error && <p style={{ color: 'red', marginTop: '15px' }}>{error}</p>}
      </form>
      <p style={{ marginTop: '20px', color: '#555' }}>
        Не маєш акаунту? <Link to="/register" style={{ color: '#007BFF', textDecoration: 'none' }}>Зареєструйся</Link>
      </p>
    </div>
  );
};

export default LoginPage;
