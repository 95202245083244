import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import LoginPage from './components/Login';
import Register from './components/Register';
import WorkPage from './components/WorkPage'; // Виправлений імпорт
import ProfileSettingsPage from './components/ProfileSettingsPage';
import MyProjectsPage from './components/MyProjectsPage';
import SubmitProblemPage from './components/SubmitProblemPage';
import SubmitIdeaPage from './components/SubmitIdeaPage';
import Subscriptions from './components/Subscriptions';
import Header from './components/Header';
import Footer from './components/Footer';
import Logout from './components/Logout';

const App = () => (
  <div>
    <Header />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/worker" element={<WorkPage />} /> {/* Виправлена назва компонента */}
      <Route path="/profile-settings" element={<ProfileSettingsPage />} />
      <Route path="/projects" element={<MyProjectsPage />} />
      <Route path="/submit-problem" element={<SubmitProblemPage />} />
      <Route path="/submit-idea" element={<SubmitIdeaPage />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
    <Footer />
  </div>
);

export default App;
