import React from 'react';

const MyProjectsPage = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Мої Участі у Проєктах</h2>
      <p>Тут відображаються ваші проєкти, у яких ви берете участь.</p>
      <ul>
        <li>Проєкт 1 - Опис...</li>
        <li>Проєкт 2 - Опис...</li>
        <li>Проєкт 3 - Опис...</li>
      </ul>
    </div>
  );
};

export default MyProjectsPage;
