import React, { useState } from 'react';
import headerImage from 'C:/Lean/my-project/src/components/header_small.png';

const HomePage = ({ isLoggedIn }) => {
  const [theme, setTheme] = useState('light');
  const [message, setMessage] = useState('');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const themeStyles = {
    backgroundColor: theme === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
    color: theme === 'light' ? '#333' : '#f4f9f4',
  };

  const cardBackground = theme === 'light' ? '#ffffff' : '#444';
  const cardTextColor = theme === 'light' ? '#333' : '#ddd';

  const articles = [
    {
      title: 'Як інновації змінюють світ: надихаючі історії успіху',
      content: 'Ідеї, які колись здавались божевільними, сьогодні формують наше майбутнє. Поділіться своєю ідеєю та станьте частиною змін!',
    },
    {
      title: 'Наука про проблеми: як виявлення проблем може призвести до інновацій',
      content: 'Багато відкриттів були зроблені завдяки тому, що хтось побачив проблему. Поділіться проблемами, які ви бачите, і ми знайдемо рішення разом!',
    },
    {
      title: 'Мислення поза рамками: чому важливо ділитися ідеями',
      content: 'Дослідження показують, що ідеї, висловлені вчасно, можуть мати потужний вплив на компанії. Додайте свою пропозицію вже сьогодні!',
    },
  ];

  const submitIdea = async () => {
    const idea = {
      title: "Ідея",
      description: "Опис вашої ідеї",
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://lean.avtologistika.com/api/ideas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(idea),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Ідея успішно подана');
      } else {
        setMessage(`Помилка подання ідеї: ${data.message}`);
      }
    } catch (error) {
      console.error('Error submitting idea:', error);
      setMessage('Сталася помилка під час подання ідеї');
    }
  };

  const submitIssue = async () => {
    const issue = {
      title: "Проблема",
      description: "Опис проблеми",
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://lean.avtologistika.com/api/issues', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(issue),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Проблема успішно подана');
      } else {
        setMessage(`Помилка подання проблеми: ${data.message}`);
      }
    } catch (error) {
      console.error('Error submitting issue:', error);
      setMessage('Сталася помилка під час подання проблеми');
    }
  };

  return (
    <div style={{
      backgroundImage: `url(${headerImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{ ...themeStyles, flex: 1, padding: '20px', overflow: 'auto', backdropFilter: 'brightness(0.8)' }}>
        <div onClick={toggleTheme} style={{
          cursor: 'pointer',
          fontSize: '1.5em',
          color: '#ffffff',
          textAlign: 'right',
        }}>
          {theme === 'light' ? '🌙' : '☀️'}
        </div>

        <section style={{
          textAlign: 'center',
          padding: '40px',
          color: theme === 'light' ? '#004085' : '#ddd',
        }}>
          <h1 style={{ fontSize: '2.5em', fontWeight: 'bold' }}>Ваша думка має значення!</h1>
          <p style={{ fontSize: '1.2em', lineHeight: '1.6', maxWidth: '700px', margin: '20px auto' }}>
            Avtologistika — це платформа, створена для вас і заради вас. Ми прагнемо зробити наш сервіс максимально зручним, ефективним та інноваційним. Діліться своїми думками та допомагайте нам ставати кращими!
          </p>
          {isLoggedIn && (
            <div style={{ marginTop: '30px' }}>
              <button style={{
                padding: '15px 30px',
                margin: '10px',
                backgroundColor: '#007BFF',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '1em',
                fontWeight: 'bold',
                letterSpacing: '1px',
                transition: 'background-color 0.3s ease',
              }}
                onClick={submitIdea}
              >
                Подати Ідею
              </button>
              <button style={{
                padding: '15px 30px',
                margin: '10px',
                backgroundColor: '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '1em',
                fontWeight: 'bold',
                letterSpacing: '1px',
                transition: 'background-color 0.3s ease',
              }}
                onClick={submitIssue}
              >
                Подати Проблему
              </button>
              {message && <p style={{ color: 'red', marginTop: '15px' }}>{message}</p>}
            </div>
          )}
        </section>

        <section style={{
          backgroundColor: theme === 'light' ? 'rgba(240, 244, 248, 0.9)' : 'rgba(85, 85, 85, 0.9)',
          padding: '40px',
          width: '100%',
          textAlign: 'center',
          borderRadius: '8px',
          marginTop: '20px',
        }}>
          <h2 style={{ fontSize: '2em', fontWeight: 'bold', color: theme === 'light' ? '#004085' : '#ddd' }}>Цікаві Статті</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
            {articles.map((article, index) => (
              <div key={index} style={{
                backgroundColor: cardBackground,
                color: cardTextColor,
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                width: '300px',
                textAlign: 'left',
              }}>
                <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>{article.title}</h3>
                <p style={{ fontSize: '1em', lineHeight: '1.6' }}>{article.content}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
