import React from 'react';

const Subscriptions = () => {
    // Дані про підписки користувача
    const subscriptions = [
        { title: 'Проблема: Висока витрата палива', description: 'Інформація про причини та рішення щодо зменшення витрат палива.', date: 'Дата підписки: 1 тиждень тому' },
        { title: 'Ідея: Оптимізація маршруту доставки', description: 'Пропозиція оптимізувати маршрут для зменшення часу доставки.', date: 'Дата підписки: 2 тижні тому' },
        { title: 'Проблема: Часті поломки двигуна', description: 'Обговорення можливих причин частих поломок і способів їх уникнути.', date: 'Дата підписки: 3 дні тому' },
    ];

    return (
        <section style={{ padding: '2rem', backgroundColor: '#f5f5f5' }}>
            <h2>Мої підписки</h2>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1.5rem' }}>
                {subscriptions.map((subscription, index) => (
                    <div key={index} style={{ padding: '1rem', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        <h3>{subscription.title}</h3>
                        <p>{subscription.description}</p>
                        <small>{subscription.date}</small>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Subscriptions;
